import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import AuthContainer from '../components/AuthContainer';
import FormInput from '../components/FormInput';
import './RegistrationPage.css';
import FormButton from '../components/FormButton';
import newtonLogo from '../assets/newton-logo.png';
import { signUp } from '../services/supabaseClient';
import AuthLayout from '../components/AuthLayout';
import { getUserDetails, checkCode } from '../services/userInfoService';

const RegistrationPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isCodeValid, setIsCodeValid] = useState(false);
    const navigate = useNavigate();
    const { code } = useParams();
    const location = useLocation();

    const validateCode = useCallback(async () => {
        if (code) {
            try {
                const practiceId = await checkCode(code);
                if (practiceId) {
                    setIsCodeValid(true);
                } else {
                    setError("Invalid registration code");
                }
            } catch (error) {
                setError("Error validating registration code");
            }
        } else {
            setError("Registration code is required");
        }
    }, [code]);

    useEffect(() => {
      validateCode();
    }, [validateCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isCodeValid) {
            setError("Please use a valid registration link");
            return;
        }
        setLoading(true);
        setError('');
        try {
            const practiceId = await checkCode(code);
            if (!practiceId) {
                throw new Error("Invalid registration code");
            }

            const { data, error } = await signUp(email, password, practiceId);
            if (error) throw error;

            const details = await getUserDetails(email);
            localStorage.setItem('practiceId', details.practiceId);

            navigate('/home');
        } catch (error) {
            setError(error.message || "An error occurred during registration");
        } finally {
            setLoading(false);
        }
    };

    if (!code) {
        return (
            <AuthLayout>
                <img src={newtonLogo} alt="Company Logo" className="company-logo" />
                <AuthContainer title="Registration Error">
                    <p>A valid registration code is required to create an account.</p>
                    <p>Please use the registration link provided to you.</p>
                    <FormButton
                        label={'Go to Login'}
                        onClick={() => navigate('/login')}
                    />
                </AuthContainer>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout>
            <img src={newtonLogo} alt="Company Logo" className="company-logo" />
            <AuthContainer title="Create an account">
                {isCodeValid ? (
                    <form onSubmit={handleSubmit}>
                        <FormInput
                            type="email"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormInput
                            type="password"
                            label="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        {error && <p className="error">{error}</p>}
                        <FormButton
                            label={'Register'}
                            disabled={loading}
                        />
                        <p>
                            Already have an account? <a href="/login">Log in</a>
                        </p>
                    </form>
                ) : (
                    <div>
                        <p>{error}</p>
                        <FormButton
                            label={'Go to Login'}
                            onClick={() => navigate('/login')}
                        />
                    </div>
                )}
            </AuthContainer>
        </AuthLayout>
    );
};

export default RegistrationPage;