import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import './output.css'
import LoginPage from './pages/LoginPage';
import RegistrationPage from './pages/RegistrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SettingsPage from './pages/SettingsPage';
import AccountPage from './pages/settings/AccountPage';
import OrganizationRegistrationPage from './pages/onboarding/OrganizationRegistrationPage'
import AddPracticePage from './pages/onboarding/AddPracticePage';
//import AddMemberPage from './pages/onboarding/AddMemberPage'
import CalendarPage from './pages/schedule/CalendarPage';
import PatientsPage from './pages/patients/PatientsPage';
import ActivityPage from './pages/Activity/Activity.js';
import PatientPage from './pages/patients/PatientPage';
import RunDemoPage from './pages/RunDemo/RunDemoPage.js';
import MessagesPage from './pages/Messages/MessagePage.js';
import DashboardPage from './pages/dashboard/dashboardPage';
import ConversationPage from './pages/Conversations/Conversation';
import ConfirmationsPage from './pages/Confirmations/Confirmations';
import UpdatePasswordPage from './pages/UpdatePassword/UpdatePasswordPage';
import VoiceDemoPage from './pages/RunVoiceDemo/RunVoiceDemo';
import { NotificationProvider } from './NotificationContext';
import Home from './views/Home';
import Conversations from './views/Conversations';
import Chat from './views/Chat';
import Patients from './views/Patients';
import Conversation from './views/Conversation';
import FormInput from './views/FormInput';
import OTPLoginPage from './views/OtpLogin';
import CallLog from './views/CallLog'
import AnalyticsDashboard from './views/Analytics'
import DialerPage from './views/Dialer'
import CalendarComponent from './views/Blocks'
import PatientPage2 from './views/Patient';
import SettingsPageNew from './views/SettingsPage';
import Engagement from './views/Engagement';

function App() {


  
  return (


    <Router>
      <NotificationProvider>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/register/:code" element={<RegistrationPage />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/settings/account" element={<AccountPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/schedule" element={<CalendarPage />} />
          <Route path="/onboarding/registration" element={<OrganizationRegistrationPage />} />
          <Route path="/onboarding/practice" element={<AddPracticePage />} />
          <Route path="/patients" element={<PatientsPage />} />
          <Route path="/patients/:id" element={<PatientPage />} />
          <Route path="/activity" element={<ActivityPage />} />
          <Route path="/rundemo" element={<RunDemoPage />} />
          <Route path="/messages" element={<MessagesPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/conversation/:id/:practiceId?" element={<ConversationPage />} />
          <Route path="/confirmations" element={<ConfirmationsPage />} />
          <Route path="/updatepassword" element={<UpdatePasswordPage/>} />
          <Route path="/VoiceDemoPage" element={<VoiceDemoPage/>} />
          <Route path="/home" element={<Home/>} />
          <Route path="/conversations" element={<Conversations/>} />
          <Route path="/conversation_/:phoneNumber" element={<Conversation/>} />
          <Route path="/chat" element={<Chat/>} />
          <Route path="/patients_" element={<Patients/>} />
          <Route path="/forms" element={<FormInput/>} />
          <Route path="/otplogin" element={<OTPLoginPage/>} />
          <Route path="/calllog" element={<CallLog/>} />
          <Route path="/analytics" element={<AnalyticsDashboard/>} />
          <Route path="/dialer" element={<DialerPage/>} />
          <Route path="/blocks" element={<CalendarComponent/>} />
          <Route path="/patient/:id" element={<PatientPage2 />} />
          <Route path="/engagement" element={<Engagement />} />


        </Routes>
      </NotificationProvider>
    </Router>
  );
}


export default App;

