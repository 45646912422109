import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import ChatInterface from './ChatInterface';
import ChatSidebar from './ChatSidebar';
import {Car, Send} from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card"
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { getConversationMessages } from '../services/conversationService';
import { useNavigate, useLocation } from 'react-router-dom'; 
import { sendMessage } from '../services/patientsService'; 

  
const Conversation = () => {

        const location = useLocation();
        const { patientName } = location.state;
        const {phoneNumber} = useParams();
        // Define the state for messages
        const [messages, setMessages] = useState([]);
        // const [practiceId] = useState('');
        const navigate = useNavigate();
        const [newMessage, setNewMessage] = useState('');
        const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');



        useEffect(() => {
            
            let practiceId = localStorage.getItem('practiceId');
            if (!practiceId) {
                console.log('No practiceId found in local storage');
                navigate('/');
                return;
            }

            console.log("phoneNumber", phoneNumber)
            
            // Prevent scrolling on the whole page
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';

            if (true) {
                window.pylon = {
                    chat_settings: {
                        app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
                        email: "user.email",
                        name: "user.fullName",
                        
                    },
                };
            }

            const fetchData = async () => {
                console.log("messages ", messages)
                const messages2 = await getConversationMessages(practiceId, phoneNumber);
                console.log("messages2 ", messages2)
                setMessages(messages2);
            }

            let intervalId;

            if (phoneNumber) {
                fetchData();
    
                intervalId = setInterval(fetchData, 1000);
            }

            // Cleanup function to re-enable scrolling if needed
            return () => {

                if (intervalId) {
                    clearInterval(intervalId);
                }

                document.documentElement.style.overflow = '';
                document.body.style.overflow = '';
            };
        }, [phoneNumber, navigate, messages]);
      

        const handleSendMessage = (e) => {
            console.log("handleSendMessage")
            e.preventDefault();
            if (newMessage.trim() !== '') {
              sendMessage(newMessage, practiceId, phoneNumber, false); // Call the service function
              setNewMessage('');
            }
          };



  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
        <div className="hidden border-r bg-muted/40 md:block">
            <Sidebar practiceId={practiceId}/>
        </div>
        <div className="flex flex-col min-h-screen overflow-hidden"> 
                <MainContent title={patientName} subtitle={phoneNumber}>
                        <div className="h-full w-full"> 
                        <Card className="flex h-3/4 flex-col mb-5">
                            <CardContent className='flex-grow overflow-auto pt-5'>
                                    {console.log('Messages2345:', messages)}
                                    {messages && messages.message ? (
                                        messages.message.map((message, index) => (
                                          <div
                                            key={index}
                                            className={`flex w-max max-w-[75%] flex-col gap-2 rounded-lg px-3 py-2 text-sm ${
                                              message.sender === 'system'
                                                ? 'ml-auto bg-muted'
                                                : 'bg-none'
                                            }`}
                                          >
                                            {message.message}
                                          </div>
                                        ))
                                      ) : (
                                        <div>No messages</div>
                                      )}
                            </CardContent>
                            <CardFooter className="h-1/4 align-center">
                            <form className="flex w-full items-center space-x-2" onSubmit={handleSendMessage}>
                            <Input
                                id="message"
                                placeholder="Type your message..."
                                className="flex-1"
                                autoComplete="off"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                />
                            <Button type="submit" onClick={handleSendMessage} size="icon">
                                <Send className="h-4 w-4" />
                                <span className="sr-only">Send</span>
                            </Button>
                            </form>
                            </CardFooter>
                        </Card>
                        </div>
                </MainContent>
        </div>
    </div>
  );
};

export default Conversation;