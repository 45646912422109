import React, { useState } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/Select";
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Checkbox } from "../ui/Checkbox";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/Dialog";
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Badge } from '../ui/Badge';

const DentalScheduler = () => {
  const [selectedOp, setSelectedOp] = useState('OP 1');
  const [blocks, setBlocks] = useState([
    {
      id: '1',
      title: 'Regular Hygiene',
      days: ['Mon', 'Tue', 'Wed', 'Thu','Fri'],
      start: 9,
      end: 11,
      resource: 'OP 1',
      types: ['Hygiene'],
      color: 'bg-blue-200'
    },
    {
      id: '2',
      title: 'Dr. Smith (Default Production)',
      days: ['Mon', 'Tue', 'Wed','Thu','Fri'],
      start: 14,
      end: 17,
      resource: 'OP 2',
      types: ['New Patients', 'Crowns', 'Fillings'],
      color: 'bg-green-200'
    },
    {
      id: '3',
      title: 'Dr. Smith (Emergencies)',
      days: ['Tue','Thu'],
      start: 14,
      end: 17,
      resource: 'OP 2',
      types: ['Emergencies', 'New Patients', 'Crowns', 'Fillings'],
      color: 'bg-red-200'
    },
    {
      id: '4',
      title: 'Dr. Smith (Consultations)',
      days: ['Mon', 'Tue', 'Wed','Thu'],
      start: 14,
      end: 17,
      resource: 'OP 2',
      types: ['Cosmetic Consults', 'New Patients', 'Crowns', 'Fillings'],
      color: 'bg-blue-200'
    },
    {
      id: '5',
      title: 'Perio & Hygiene',
      days: ['Mon', 'Tue', 'Wed','Thu','Fri'],
      start: 11,
      end: 12,
      resource: 'OP 1',
      types: ['Perio Cleanings', 'Hygiene'],
      color: 'bg-blue-200'
    },
  ]);

  const [newBlock, setNewBlock] = useState({
    title: '',
    days: [],
    start: '',
    end: '',
    resource: 'OP 1',
    types: '',
    color: 'bg-purple-200'
  });

  const [isOpen, setIsOpen] = useState(false);

  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const hours = Array.from({ length: 11 }, (_, i) => i + 8); // 8 AM to 6 PM

  const filteredBlocks = blocks.filter(block => block.resource === selectedOp);

  const renderBlock = (day, hour) => {
    const block = filteredBlocks.find(b => b.days.includes(day) && hour >= b.start && hour < b.end);
    if (block) {
      return (
        <div className={`${block.color} p-1 text-xs h-full flex items-center justify-center`}>
          {block.title}
        </div>
      );
    }
    return null;
  };

  const handleNewBlockChange = (e) => {
    const { name, value } = e.target;
    setNewBlock(prev => ({ ...prev, [name]: value }));
  };

  const handleDayToggle = (day) => {
    setNewBlock(prev => ({
      ...prev,
      days: prev.days.includes(day)
        ? prev.days.filter(d => d !== day)
        : [...prev.days, day]
    }));
  };

  const handleCreateBlock = () => {
    if (newBlock.title && newBlock.days.length && newBlock.start && newBlock.end) {
      setBlocks(prev => [
        ...prev,
        {
          ...newBlock,
          id: String(prev.length + 1),
          start: parseInt(newBlock.start),
          end: parseInt(newBlock.end),
          types: newBlock.types.split(',').map(type => type.trim())
        }
      ]);
      setNewBlock({
        title: '',
        days: [],
        start: '',
        end: '',
        resource: 'OP 1',
        types: '',
        color: 'bg-purple-200'
      });
      setIsOpen(false);
    } else {
      alert('Please fill in all required fields');
    }
  };

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
    <div className="hidden border-r bg-muted/40 md:block">
      <Sidebar practiceId={"Dosset"}/>
    </div>
    <div className="flex flex-col min-h-screen">
      <MainContent title="Blocks">
    <div className="container mx-auto p-4">
      <div className="mb-4 flex justify-between items-center">
        <div className="flex gap-4">
          <Dialog open={isOpen} onOpenChange={setIsOpen}>
            <DialogTrigger asChild>
              <Button>Create a block</Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Create a new block</DialogTitle>
              </DialogHeader>
              <div className="space-y-4 mt-4">
                <Input
                  placeholder="Block Title"
                  name="title"
                  value={newBlock.title}
                  onChange={handleNewBlockChange}
                />
                <div className="flex flex-wrap gap-2">
                  {daysOfWeek.map(day => (
                    <div key={day} className="flex items-center">
                      <Checkbox
                        id={`day-${day}`}
                        checked={newBlock.days.includes(day)}
                        onCheckedChange={() => handleDayToggle(day)}
                      />
                      <label htmlFor={`day-${day}`} className="ml-2">{day}</label>
                    </div>
                  ))}
                </div>
                <Input
                  placeholder="Start Time (e.g., 9)"
                  name="start"
                  value={newBlock.start}
                  onChange={handleNewBlockChange}
                  type="number"
                  min="8"
                  max="17"
                />
                <Input
                  placeholder="End Time (e.g., 17)"
                  name="end"
                  value={newBlock.end}
                  onChange={handleNewBlockChange}
                  type="number"
                  min="9"
                  max="18"
                />
                <Input
                  placeholder="Types (comma-separated)"
                  name="types"
                  value={newBlock.types}
                  onChange={handleNewBlockChange}
                />
                <Select
                  name="resource"
                  onValueChange={(value) => setNewBlock(prev => ({ ...prev, resource: value }))}
                >
                  <SelectTrigger>
                    <SelectValue placeholder={newBlock.resource} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="OP 1">OP 1</SelectItem>
                    <SelectItem value="OP 2">OP 2</SelectItem>
                  </SelectContent>
                </Select>
                <Button onClick={handleCreateBlock}>Create Block</Button>
              </div>
            </DialogContent>
          </Dialog>
          <Select onValueChange={(value) => setSelectedOp(value)}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder={selectedOp} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="OP 1">OP 1</SelectItem>
              <SelectItem value="OP 2">OP 2</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="flex">
        <div className="w-1/4 pr-4">
          <h2 className="font-bold mt-4 mb-2">Existing blocks</h2>
          {filteredBlocks.map((block) => (
            <div key={block.id} className="mb-2">
              <h3 className="font-semibold">{block.title}:</h3>
                {block.types.map((type, index) => (
                  <Badge variant='outline' key={index} className="mr-1 mb-1">{type}</Badge>
                ))}
              <p className="text-sm">{block.days.join(', ')} {block.start}:00 - {block.end}:00</p>
              <div className={`w-full h-4 ${block.color} mt-1`}></div>
            </div>
          ))}
        </div>

        <div className="w-3/4">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border p-2">Time</th>
                {daysOfWeek.map(day => (
                  <th key={day} className="border p-2">{day}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {hours.map(hour => (
                <tr key={hour}>
                  <td className="border p-2">{hour}:00</td>
                  {daysOfWeek.map(day => (
                    <td key={`${day}-${hour}`} className="border p-2 h-12">
                      {renderBlock(day, hour)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </MainContent>
    </div>
  </div>
  );
};

export default DentalScheduler;