import React, { useState, useEffect } from 'react';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table"
import { fetchCallData } from '../services/userInfoService';
import { useNavigate } from 'react-router-dom';

const CallLog = () => {
  const [callData, setCallData] = useState([]);
  const [globalPracticeId, setGlobalPracticeId] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
    setGlobalPracticeId(practiceId);

    const fetchCalls = async () => {
      try {
        console.log("fetchCalls ", globalPracticeId)
        let actPracticeId = globalPracticeId
        if(globalPracticeId === "pAdmin")
        {
            actPracticeId = ["p1", "p2", "p3"]
        }
        const data = await fetchCallData(actPracticeId);
        setCallData(data);
        console.log("Fetched call data:", data);
      } catch (error) {
        console.error('Error fetching call data:', error);
      }
    };

    fetchCalls(); // Initial fetch

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchCalls, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [globalPracticeId, navigate]);

  return (
    <div className="flex flex-col min-h-screen w-full">
      <div className="flex-grow p-6">
        <Table>
          <TableCaption>After Hours Call Log</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead>Number</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Summary</TableHead>
              <TableHead>Location</TableHead>
              <TableHead>Time Called</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {callData.map(call => (
              <TableRow key={call.id}>
                <TableCell>{call.phoneNumber}</TableCell>
                <TableCell>{call.name}</TableCell>
                <TableCell>{call.details}</TableCell>
                <TableCell>{call.location}</TableCell>
                <TableCell>{call.time}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default CallLog;