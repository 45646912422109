// src/views/Home.js
import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Input } from '../ui/Input'; // Import the custom Input component
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/Table"
  import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
  } from "../ui/Tabs"
  import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../ui/Dialog"
  import { getActivities, confirmActivity, ManualHandleActivity, GetPracticeStats, getThrowsByPracticeId, markThrowDocumentResolved, addToWaitlist, getWaitlistPatients, getAllPatientNames, deleteWaitlistPatient } from '../services/ActivityService';
  import { useNavigate } from 'react-router-dom'; 
  import AnalyticsDashboard from  './Analytics';
  import CallLog from './CallLog'
  import { Filter as FilterIcon, Download as ExportIcon, Pencil, Trash2 } from 'lucide-react';


const Home = () => {
    const [activities, setActivities] = useState([]);
    const [practiceIdGlobal, setPracticeIdGlobal] = useState('');
    const [patientName, setPatientName] = useState('');
    const [preferences, setPreferences] = useState('');
    const [activeTab, setActiveTab] = useState('booked');
    const [waitlist, setWaitlist] = useState([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const today = new Date();
    const [allPatientNames, setAllPatientNames] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [practiceIdAdmin, setPracticeIdAdmin] = useState(localStorage.getItem('practiceIdAdmin') || '');
    const [editingPatient, setEditingPatient] = useState(null);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    //const navigate = useNavigate();

    const handleEditClick = (patient) => {
        setEditingPatient(patient);
        setIsEditDialogOpen(true);
    };

    const handleEditSubmit = async () => {
        try {
            await addToWaitlist(editingPatient.name, editingPatient.preferenceString, practiceIdGlobal);
            console.log('Patient updated successfully');
            setIsEditDialogOpen(false);
            await fetchWaitlist();
        } catch (error) {
            console.error('Failed to update patient:', error);
            alert('Error occurred while updating patient');
        }
    };

    const handleDeleteClick = async (patientId) => {
        if (window.confirm('Are you sure you want to delete this patient from the waitlist?')) {
            try {
                await deleteWaitlistPatient(patientId, practiceIdGlobal);
                console.log('Patient deleted successfully');
                await fetchWaitlist();
            } catch (error) {
                console.error('Failed to delete patient:', error);
                alert('Error occurred while deleting patient');
            }
        }
    };

    const fetchWaitlist = async () => {
        const practiceId = localStorage.getItem('practiceId');
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            return;
        }
    
        try {
            const waitlistData = await getWaitlistPatients(practiceId);
            setWaitlist(waitlistData); 
            console.log("Updated waitlistData:", waitlistData);
        } catch (error) {
            console.error('Error fetching waitlist:', error);
        }
    };
    
    const fetchAllPatientNames = async () => {
        const practiceId = localStorage.getItem('practiceId');
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            return;
        }
    
        try {
            const patientNames = await getAllPatientNames(practiceId);
            setAllPatientNames(patientNames);
        } catch (error) {
            console.error('Error fetching all patient names:', error);
        }
    };
    
    useEffect(() => {
        const fetchInitialData = async () => {
            const practiceId = localStorage.getItem('practiceId');
            if (practiceId) {
                setPracticeIdGlobal(practiceId);
                console.log("practiceId23 ", practiceId)
                try {
                    let actPracticeId = practiceId;
                    if(practiceId === "pAdmin")
                    {
                        actPracticeId = ["p1", "p2", "p3"]
                    }
                    let activitiesData = await getActivities(actPracticeId);
                    const FILTER_ACTED_ON_PRACTICE_IDS = ['wynn', 'starBrite', 'starBriteFremont', 'GraceDental'];
                    if (FILTER_ACTED_ON_PRACTICE_IDS.includes(practiceId)) {
                        activitiesData = activitiesData.filter(activity => activity.ActedOn === true);
                    }
                    setActivities(activitiesData);
                    console.log("Fetched activities:", activitiesData);
    
                    await fetchWaitlist();
                    await fetchAllPatientNames();
                } catch (error) {
                    console.error('Error fetching initial data:', error);
                }
            } else {
                console.log('No practiceId found in local storage');
            }
        };
    
        fetchInitialData();
    }, []);

    const handlePatientNameChange = (event) => {
        // Check if the argument is an event or a direct value
        const value = typeof event === 'object' ? event.target.value : event;
      
        setPatientName(value);
        console.log("Updated suggestions state: ", suggestions); 
        if (typeof value !== 'string' || value.length < 2) {
          setSuggestions([]);
          return;
        }
      
        const filteredNames = allPatientNames
          .filter(name => name.toLowerCase().includes(value.toLowerCase()))
          .slice(0, 10);

        console.log("filteredNames ", filteredNames)
      
        setSuggestions(filteredNames);
      };
      
      const handleSuggestionClick = (name) => {
        setPatientName(name);
        setSuggestions([]);
      };

    const handleSubmit = async () => {
        console.log('Patient Name:', patientName);
        console.log('Preferences:', preferences);
        try {
            await addToWaitlist(patientName, preferences, practiceIdGlobal);
            console.log('Patient added to waitlist successfully');
            setIsDialogOpen(false);  // Close the dialog
            setPatientName('');  // Clear the form
            setPreferences('');
            await fetchWaitlist();  // Refresh the waitlist immediately
        } catch (error) {
            console.error('Failed to add patient to waitlist:', error);
            alert('Error occured, try rephrasing your preferences');
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        
        // Check if the dateString matches the specific format "YYYY-MM-DDTHH"
        if (/^\d{4}-\d{2}-\d{2}T\d{2}$/.test(dateString)) {
          const [year, month, day, hour] = dateString.split(/[-T]/);
          const date = new Date(year, month - 1, day, hour);
          return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
          });
        }
        
        // For all other formats, use the original parsing logic
        return new Date(dateString).toLocaleString([], {
          month: 'short', 
          day: 'numeric', 
          hour: 'numeric', 
          minute: '2-digit',
          hour12: true
        });
      };

      const columnDefinitions = [
        { key: 'time', header: 'Appointment Time', format: (value) => formatDate(value) },
        { key: 'type', header: 'Type' },
        { key: 'name', header: 'Patient' },
        { key: 'location', header: 'Location' },
        { key: 'timebooked', header: 'Time Booked' },
        { key: 'channel', header: 'Channel' },
        { key: 'description', header: 'Description' }
      ];

      return (
        <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
            <div className="hidden border-r bg-muted/40 md:block">
                <Sidebar practiceId={practiceIdGlobal}/>
            </div>
    
            <div className="flex flex-col min-h-screen">
                <MainContent title="Home">
                <Tabs defaultValue="booked" onValueChange={setActiveTab}>
                <div className='flex justify-between items-center'>
                <TabsList>
                    <TabsTrigger value="booked">Booked Patients</TabsTrigger>
                    {practiceIdGlobal !== "Southern Op" && <TabsTrigger value="waitlist">Waitlist</TabsTrigger>}
                    {(practiceIdGlobal === "acevedo" || practiceIdGlobal === "wynn" || practiceIdAdmin === "Smiles Dental Admin") && <TabsTrigger value="analytics">Analytics</TabsTrigger>}
                    <TabsTrigger value="calllog">CallLog</TabsTrigger>
                </TabsList>

                <div className="flex space-x-3">
                        <Dialog>
                            <DialogTrigger className="inline-flex items-center space-x-2 text-sm text-muted-foreground leading-tight hover:text-foreground transition-colors mr-4">
                                <FilterIcon size={16} />
                                <span>Filter</span>
                            </DialogTrigger>
                            </Dialog>
                            <Dialog>
                            <DialogTrigger className="inline-flex items-center space-x-2 text-sm text-muted-foreground leading-tight hover:text-foreground transition-colors">
                                <ExportIcon size={16} />
                                <span>Export</span>
                            </DialogTrigger>
                        </Dialog>
                </div>
                </div>

                <TabsContent value="booked">
                    <Table>
                        <TableCaption>Bookings</TableCaption>
                        <TableHeader>
                        <TableRow>
                            {columnDefinitions.map(column => (
                            <TableHead key={column.key}>{column.header}</TableHead>
                            ))}
                        </TableRow>
                        </TableHeader>
                        <TableBody>
                        {activities.map(activity => (
                            <TableRow key={activity.id}>
                            {columnDefinitions.map(column => (
                                <TableCell key={column.key}>
                                {column.format 
                                    ? column.format(activity[column.key], activity)
                                    : activity[column.key] || ''}
                                </TableCell>
                            ))}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TabsContent>
                    <TabsContent value="waitlist">
                        <Table>
                            <TableCaption>Waitlist Patients</TableCaption>
                            <TableHeader>
                                <TableRow>
                                    <TableHead>Patient Name</TableHead>
                                    <TableHead>Preferences</TableHead>
                                    <TableHead>Messaged?</TableHead>
                                    <TableHead>Booked?</TableHead>
                                    <TableHead>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                            {waitlist.map(patient => (
                                <TableRow key={patient.id}>
                                    <TableCell>{patient.name}</TableCell>
                                    <TableCell>{patient.preferenceString}</TableCell>
                                    <TableCell>{patient.messaged}</TableCell>
                                    <TableCell>{patient.booked}</TableCell>
                                    <TableCell>
                                        <button onClick={() => handleEditClick(patient)} className="mr-2">
                                            <Pencil size={16} />
                                        </button>
                                        <button onClick={() => handleDeleteClick(patient.id)}>
                                            <Trash2 size={16} />
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TabsContent>
                    <TabsContent value="analytics">
                        <AnalyticsDashboard practiceId={practiceIdGlobal} />
                    </TabsContent>
                    <TabsContent value="calllog">
                        <CallLog />
                    </TabsContent>
                </Tabs>
                {activeTab === 'waitlist' && (
                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <DialogTrigger asChild>
                            <button className="p-2 bg-blue-500 text-white rounded" onClick={() => setIsDialogOpen(true)}>Add Patient</button>
                        </DialogTrigger>
                        <DialogContent className="overflow-visible">
                            <DialogHeader>
                                <DialogTitle>Add New Patient</DialogTitle>
                            </DialogHeader>
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit().catch(error => {
                                    console.error('Error in form submission:', error);
                                });
                            }}>
                                <div className="relative mb-4">
                                    <Input
                                        placeholder="Patient Name"
                                        value={patientName}
                                        onChange={handlePatientNameChange}
                                    />
                                    {suggestions.length > 0 && (
                                        <ul className="absolute z-50 w-full bg-white border border-gray-300 mt-1 max-h-60 overflow-auto rounded-md shadow-lg">
                                            {suggestions.map((name, index) => (
                                                <li
                                                    key={index}
                                                    className="p-2 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleSuggestionClick(name)}
                                                >
                                                    {name}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <Input
                                    placeholder="Preferences"
                                    value={preferences}
                                    onChange={(e) => setPreferences(e.target.value)}
                                    className="mb-4"
                                />
                                <DialogFooter>
                                    <button type="submit" className="bg-primary text-white px-4 py-2 rounded-md">
                                        Submit
                                    </button>
                                </DialogFooter>
                            </form>
                        </DialogContent>
                    </Dialog>
                )}
                </MainContent>
            </div>

            <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Edit Patient Preferences</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleEditSubmit();
                    }}>
                        <Input
                            placeholder="Patient Name"
                            value={editingPatient?.name || ''}
                            onChange={(e) => setEditingPatient({...editingPatient, name: e.target.value})}
                            className="mb-4"
                        />
                        <Input
                            placeholder="Preferences"
                            value={editingPatient?.preferenceString || ''}
                            onChange={(e) => setEditingPatient({...editingPatient, preferenceString: e.target.value})}
                            className="mb-4"
                        />
                        <DialogFooter>
                            <button type="submit" className="bg-primary text-white px-4 py-2 rounded-md">
                                Update
                            </button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Home;
