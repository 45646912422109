const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'


function fetchFromAPI(endpoint, options = {}) {
    return fetch(`${BASE_URL}${endpoint}`, options)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}

async function checkCode(code)
{
    const data = await fetchFromAPI(`/api/checkCode/${code}`);
    return data.practiceId;
}

async function getUserDetails(userId) {
    try {
        // Step 1: Get Practice ID

        console.log("userId", userId)

        const practices = await fetchFromAPI(`/api/users/${userId}`);
        const practiceId = practices[0]?.practiceId; // Assuming the first practice ID is the one you need

        if (!practiceId) {
            throw new Error('Practice ID not found for user');
        }

        // // Step 2: Get Practice Location
        // const locations = await fetchFromAPI(`/api/practices/location/${practiceId}`);
        // const location = locations[0]?.location; // Assuming the location structure you need

        // // Step 3: Get Organization Name
        // const organizationNames = await fetchFromAPI(`/api/organizations/name/${practiceId}`);
        // const organizationName = organizationNames[0]?.name; // Assuming the first name is the one you need

        return {
            userId,
            practiceId
        };
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
}

async function fetchFormData(practiceId, type) {
    try {
        console.log(`Fetching from: /api/forms/${practiceId}/${type}`);
        const data = await fetchFromAPI(`/api/forms/${practiceId}/${type}`);
        console.log("data ", data);
        return data;
    } catch (error) {
        console.error('Error fetching form data:', error);
        throw error;
    }
}

async function updateFormData(practiceId, type, updatedFormData) {
    try {
        console.log(`Updating form data for: /api/forms/${practiceId}/${type}`);
        const endpoint = `/api/editforms/${practiceId}/${type}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ formData: updatedFormData }),
        };

        const data = await fetchFromAPI(endpoint, options);
        console.log("Updated data ", data);
        return data;
    } catch (error) {
        console.error('Error updating form data:', error);
        throw error;
    }
}

export async function submitFormResponse(responseDocument) {
    try {
      console.log('Submitting form response');
      const endpoint = '/api/submitFormResponse';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(responseDocument),
      };
  
      const data = await fetchFromAPI(endpoint, options);
      console.log("Submitted response data:", data);
      return data;
    } catch (error) {
      console.error('Error submitting form response:', error);
      throw error;
    }
}

export async function fetchCallData(practiceIdOrIds) {
    try {
        let endpoint;
        if (Array.isArray(practiceIdOrIds)) {
            console.log(`Fetching call data for practices: ${practiceIdOrIds.join(', ')}`);
            endpoint = `/api/calls?practiceIds=${practiceIdOrIds.join(',')}`;
        } else {
            console.log(`Fetching call data for practice: ${practiceIdOrIds}`);
            endpoint = `/api/calls/${practiceIdOrIds}`;
        }
        
        const data = await fetchFromAPI(endpoint);
        console.log("Fetched call data:", data);
        return data;
    } catch (error) {
        console.error('Error fetching call data:', error);
        throw error;
    }
}

async function fetchReactivationSettings(practiceId) {
    async function attemptFetch(id) {
        console.log(`Fetching from: /api/reactivationSettings/${id}`);
        const data = await fetchFromAPI(`/api/reactivationSettings/${id}`);
        console.log("Fetched reactivation settings: ", data);
        return data;
    }

    try {
        return await attemptFetch(practiceId);
    } catch (error) {
        console.error('Error fetching reactivation settings:', error);
        console.log("Error encountered. Retrying with 'Sams Practice'");
        try {
            return await attemptFetch("Sams Practice");
        } catch (retryError) {
            console.error('Error in retry attempt:', retryError);
            throw retryError; // Throw the retry error if it also fails
        }
    }
}

async function updateReactivationSettings(practiceId, updatedSettings) {
    async function attemptUpdate(id) {
        console.log(`Updating reactivation settings for practice: ${id}`);
        const endpoint = `/api/reactivationSettings/${id}`;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedSettings),
        };

        const data = await fetchFromAPI(endpoint, options);
        console.log("Updated reactivation settings: ", data);
        return data;
    }

    try {
        return await attemptUpdate(practiceId);
    } catch (error) {
        console.error('Error updating reactivation settings:', error);
        console.log("Error encountered. Retrying with 'Sams Practice'");
        try {
            return await attemptUpdate("Sams Practice");
        } catch (retryError) {
            console.error('Error in retry attempt:', retryError);
            throw retryError; // Throw the retry error if it also fails
        }
    }
}


export { getUserDetails, fetchFromAPI, checkCode, fetchFormData, updateFormData, fetchReactivationSettings, updateReactivationSettings };

