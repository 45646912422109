import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { Badge } from '../ui/Badge';
import { Card, CardHeader, CardTitle, CardContent } from '../ui/Card';

interface MessageProps {
  data: {
    title: string;
    status: 'active' | 'pending' | 'completed';
    copy: string;
  };
}

const Message: React.FC<MessageProps> = ({ data }) => {
  const statusColor = {
    active: 'bg-green-500',
    pending: 'bg-yellow-500',
    completed: 'bg-blue-500'
  };

  return (
    <Card className="w-64 shadow-lg">
      <CardHeader className="pb-2">
        <div className="flex justify-between items-center">
          <CardTitle className="text-lg">{data.title}</CardTitle>
          <Badge className={`${statusColor[data.status]} text-white`}>
            {data.status}
          </Badge>
        </div>
      </CardHeader>
      <CardContent>
        <p className="text-sm text-gray-600">{data.copy}</p>
      </CardContent>
      <Handle type="target" position={Position.Top} className="w-3 h-3" />
      <Handle type="source" position={Position.Bottom} className="w-3 h-3" />
    </Card>
  );
};

export default Message;