import React, { useState, useEffect, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import { getConversationsByPractice } from '../services/messagesService';
import { useNavigate } from 'react-router-dom';
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [globalPracticeId, setGlobalPracticeId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const pageSize = 10;

  const fetchConversations = useCallback((page, search) => {
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }

    setGlobalPracticeId(practiceId);

    let practiceIdForActivities = practiceId;
    if (practiceId === "Admin") {
      practiceIdForActivities = ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "IAS", "nkc1", "starBriteFremont", "Acevedo1"];
    }
    if (practiceId === "pAdmin") {
      practiceIdForActivities = ["p1", "p2", "p3"];
    }

    getConversationsByPractice(practiceIdForActivities, page, pageSize, search)
      .then(data => {
        const modifiedConversations = data.map(convo => ({
          id: convo.id || '',
          timeStarted: convo.timeStarted || 'n/a',
          summary: convo.summary || 'n/a',
          type: convo.type || 'Text',
          patientName: convo.name || '',
          nextAppointment: convo.nextAppointment || 'n/a',
          phoneNumber: convo.phoneNumber,
          location: convo.location
        }));

        setConversations(modifiedConversations);
      })
      .catch(error => setError(error));
  }, [navigate]);


  useEffect(() => {
    fetchConversations(currentPage, searchTerm);
  }, [currentPage, fetchConversations, searchTerm]);


  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleRowClick = (convo) => {
    navigate(`/conversation_/${convo.phoneNumber}`, {
      state: {
        patientName: convo.patientName,
      }
    });
  };

  const handleSearch = () => {
    setCurrentPage(1);
    fetchConversations(1, searchTerm);
  };

  const handleNextPage = () => {
    setCurrentPage(prev => prev + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  return (
    <div className="grid h-screen w-screen md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={globalPracticeId}/>
      </div>
      <div className="flex flex-col min-h-screen">
        <MainContent title="Conversations">
          <div className="flex mb-4">
            <Input
              type="text"
              placeholder="Search conversations..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="mr-2"
            />
            <Button onClick={handleSearch}>Search</Button>
          </div>
          <Table>
            <TableCaption>Conversations</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Type</TableHead>
                <TableHead>Patient Name</TableHead>
                <TableHead>Location</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {conversations.map((convo, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(convo)}
                  className="cursor-pointer"
                >
                  <TableCell>
                    <Badge>{convo.type}</Badge>
                  </TableCell>
                  <TableCell>{convo.patientName}</TableCell>
                  <TableCell>{convo.location}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="mt-4 flex justify-between">
            <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous Page</Button>
            <Button onClick={handleNextPage}>Next Page</Button>
          </div>
        </MainContent>
      </div>
    </div>
  );
};

export default Conversations;