import React from 'react';
import { 
  ReactFlow,
  Node,
  Edge,
  Controls, 
  Background, 
  useNodesState, 
  useEdgesState,
  MiniMap,
  NodeTypes
} from '@xyflow/react';
import Message from './Message';  // Assuming Message is in the same directory

import '@xyflow/react/dist/style.css';

// Define the structure of your node data
type MessageData = {
  title: string;
  status: 'text' | 'phone' | 'e-mail';
  copy: string;
  [key: string]: unknown;  // Index signature to satisfy Record<string, unknown>
};

// Define your custom node type
type CustomNode = Node<MessageData>;

const nodeTypes: NodeTypes = {
  message: Message,
};

const initialNodes: CustomNode[] = [
  { 
    id: '1', 
    type: 'message',
    position: { x: 0, y: 0 }, 
    data: { 
      title: 'Welcome', 
      status: 'text',
      copy: 'Welcome to our clinic! We look forward to your first visit.'
    } 
  },
  { 
    id: '2', 
    type: 'message',
    position: { x: 0, y: 200 }, 
    data: { 
      title: 'Follow-up', 
      status: 'text',
      copy: "It's time for your follow-up appointment. How are you feeling?"
    } 
  },
  { 
    id: '3', 
    type: 'message',
    position: { x: 0, y: 400 }, 
    data: { 
      title: 'Reminder', 
      status: 'phone',
      copy: "Don't forget your appointment tomorrow at 2 PM."
    } 
  },
];

const initialEdges: Edge[] = [
  { id: 'e1-2', source: '1', target: '2' },
  { id: 'e2-3', source: '2', target: '3' },
];

const Engagement: React.FC = () => {
  // removed setNodes and setEdges for now
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, , onEdgesChange] = useEdgesState(initialEdges);

  return (
    <div style={{ width: '100%', height: '800px', border: '1px solid #ccc' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        fitView
        fitViewOptions={{ padding: 0.2 }}
        attributionPosition="bottom-left"
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
};

export default Engagement;