import React, { useState, useEffect, useRef } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import ChatInterface from './ChatInterface';
import ChatSidebar from './ChatSidebar';
import {Car, Send} from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card"
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { getConversationsByPractice } from '../services/messagesService';
import { sendMessage } from '../services/patientsService'; 
import { useNavigate } from 'react-router-dom';



  

const Chat = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('111')
  const [convoId, setConvoId] = useState('')
  const [globalPracticeId, setGlobalPracticeId] = useState('')
  const navigate = useNavigate();
  const cardContentRef = useRef(null);
  const isUserScrollingRef = useRef(false);




  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';

    if (true) {
         window.pylon = {
             chat_settings: {
                 app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
                 email: "user.email",
                 name: "user.fullName",
                
             },
         };
     }

    const fetchConversations = () => {

      const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
      setGlobalPracticeId(practiceId);
      console.log("convoId ",  convoId)
    getConversationsByPractice(practiceId, currentPage, 10, searchTerm, true)
      .then(data => {
        console.log("data ", data)
        const modifiedConversations = data
        .filter(convo => {
          if (practiceId !== "acevedo") {
            return !convo.hasOwnProperty('stage') || convo.stage !== 'done';
          }
          return true;
        })
        .map(convo => ({
            id: convo.id,
            name: convo.name,
            lastMessage: convo.message[convo.message.length - 1].message,
            messages: convo.message
          }));
        console.log("modifiedConversations ", modifiedConversations)
        setConversations(modifiedConversations);

        if(convoId !== '')
        {
          const specificConversation = modifiedConversations.find(convo => convo.id === convoId);
          if(specificConversation)
          {
            console.log("did we find it", specificConversation.messages)
            setMessages(specificConversation.messages);
          }
        }
      })
      .catch(error => setError(error));

    };

    fetchConversations();
    const intervalId = setInterval(fetchConversations, 1000);


    if (true) {
      const email = localStorage.getItem('email') || '';
      window.pylon = {
          chat_settings: {
              app_id: "98e8ee10-f8ab-4d78-837b-bead4ef90b72",
              email: email,
              name: globalPracticeId
          },
      };
  }


    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
    
  }, [searchTerm, currentPage, messages, convoId, navigate, globalPracticeId]);

  const handleConversationClick = (conversation) => {
    console.log("handleConversationClick", conversation);
    setConvoId(conversation.id);
    setPhoneNumber(conversation.id);
    //setMessages(conversation.messages);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
    if (newMessage.trim() !== '') {
      sendMessage(newMessage, practiceId, phoneNumber, true); // Call the service function
      setNewMessage('');
    }
  };

  useEffect(() => {
    const handleUserScroll = () => {
      const cardContent = cardContentRef.current;
      if (cardContent) {
        const isNearBottom = cardContent.scrollHeight - cardContent.scrollTop <= cardContent.clientHeight + 50;
        isUserScrollingRef.current = !isNearBottom;
      }
    };

    const cardContent = cardContentRef.current;
    if (cardContent) {
      cardContent.addEventListener('scroll', handleUserScroll);
      return () => {
        cardContent.removeEventListener('scroll', handleUserScroll);
      };
    }
  }, []);

  useEffect(() => {
    const cardContent = cardContentRef.current;
    if (cardContent && !isUserScrollingRef.current) {
      cardContent.scrollTop = cardContent.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="grid h-screen w-screen md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
        <div className="hidden border-r bg-muted/40 md:block">
            <Sidebar practiceId={globalPracticeId}/>
        </div>
        <div>
                <MainContent title="Chat" >
                    <div className="flex h-full flex-1">
                        <div className="w-1/4 border-r"> 
                        <ChatSidebar
                          conversations={conversations}
                          onConversationClick={handleConversationClick}
                          searchTerm={searchTerm}
                          setSearchTerm={setSearchTerm}
                          />       
                        </div>

                        <div className="mx-8 mb-3 w-full"> 
                        <Card className="flex flex-col h-90 w-full mb-5">
                        <CardContent ref={cardContentRef} className='h-cc  flex flex-col overflow-auto pt-5'>
                          {messages.map((message, index) => (
                            <div
                              key={index}
                              className={`!important rounded-lg mt-4 max-w-[75%] px-3 py-2 text-md ${
                                message.sender === 'user'
                                  ? 'ml-auto bg-muted'
                                  : 'bg-muted'
                              }`}
                            >
                              {message.message}
                            </div>
                          ))}
                        </CardContent>
                        
                        <CardFooter className="h-1/4 align-center flex-shrink-0">

                                        <form
                                            className="flex w-full items-center space-x-2"
                                        >
                                            <Input
                                              id="message"
                                              placeholder="Type your message..."
                                              className="flex-1"
                                              autoComplete="off"
                                              value={newMessage}
                                              onChange={(e) => setNewMessage(e.target.value)}
                                            />
                                          <Button type="submit" onClick={handleSendMessage} size="icon">
                                            <Send className="h-4 w-4" />
                                            <span className="sr-only">Send</span>
                                          </Button>
                                          </form>
                        </CardFooter>

                        </Card>
                        </div>
                    </div>
                </MainContent>
        </div>
    </div>
  );
};

export default Chat;